:root {
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 90vw;
  margin-left: 5vw;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.title-container {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.input-box {
  height: 48px;
  width: 400px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}