.swipe-border-class {
  border-color: var(--ripelocker-green) !important;

}
.swipe-caret-class {
  background: var(--ripelocker-light-green) !important;
}
.swipe-overlay-class {
  background: var(--ripelocker-green) !important;
}

.Button {
  background: var(--ripelocker-green);
}

.MuiButtonGroup-fullWidth {
  display: block !important;
}

.MuiAccordionSummary-expandIconWrapper {
  margin-left: 5px;
}

.accordionTable-details {
  padding: 8px 0 0 !important;
}

.table-row-active {
  background: var(--ripelocker-light-green);
}

.table-row-notactive {
  background: #ffffff;
}