.load-container {
  box-sizing: border-box;
  height: 100vh;
  width: 90vw;
  display: block;
  margin-left: 5vw;
}

.label-date-container {
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
  font-weight: 600;
  color: grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Dropdown-control {
  appearance: none;
  border: 0;
  outline: 0;
  /* Personalize */
  text-align: center;
  margin-top: 5px;
  padding: 1rem 1rem 1rem 1rem;
  background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  /* <option> colors */
  option {
    color: inherit;
    background-color: var(--option-bg);
  }
}

.Dropdown-control::-ms-expand {
  display: none;
}

.Dropdown-control:focus {
  outline: none;
}

.Dropdown-disabled {
  background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--arrow-disabled-bg) 3em, var(--select-disabled-bg) 3em);

  option {
    color: inherit;
    background-color: var(--option-disabled-bg);
  }
}

.Dropdown-arrow {
  border-color: transparent transparent;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent;
}

.date-picker {
  color: black;
  display: flex;
  justify-content: center;
}

.label-container,
.date-selection-container {
  display: flex;
  margin-top: 5px;
  justify-content: center;
}

.button-container {
  width: 90vw;
  margin-top: 5px;
  position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--ripelocker-green);
}