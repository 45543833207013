.Dropdown-control {
  appearance: none;
  border: 0;
  outline: 0;
  /* Personalize */
  text-align: center;
  margin-top: 5px;
  padding: 1rem 1rem 1rem 1rem;
  background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  /* <option> colors */
  option {
    color: inherit;
    background-color: var(--option-bg);
  }
}

.Dropdown-control::-ms-expand {
  display: none;
}

.Dropdown-control:focus {
  outline: none;
}

.Dropdown-disabled {
  background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--arrow-disabled-bg) 3em, var(--select-disabled-bg) 3em);

  option {
    color: inherit;
    background-color: var(--option-disabled-bg);
  }
}

.Dropdown-arrow {
  border-color: transparent transparent;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent;
}