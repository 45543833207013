:root {
  --arrow-bg: rgba(57, 181, 74, .8);
  --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
  --option-bg: rgba(57, 181, 74);
  --select-bg: rgba(57, 181, 74, 1);
  --arrow-disabled-bg: rgba(128, 128, 128, .8);
  --option-disabled-bg: rgba(128, 128, 128, 66);
  --select-disabled-bg: rgba(128, 128, 128, 1);
  --ripelocker-green: #39B54A;
  --ripelocker-light-green: #79C982;
  --ripelocker-red: #ED2222;
  --ripelocker-blue: #33B5E5;
  --ripelocker-orange: #FFBB33;
}

.ripelocker-logo {
  max-width:250px;
  max-height:125px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
}

.title-container {
  color: var(--ripelocker-green);
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 18px;
  font-weight: 600;
}

.button-container {
  background-color: var(--ripelocker-green);
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}


.button-container:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-container:hover {
  background-color: #2c974b;
}

.button-container:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-container:disabled,
.button-container:disabled:active {
  background-color: rgb(27, 31, 35, .3);
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
  box-shadow: unset;
}

.button-container:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;

}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5px;
}

.errorLabel {
  color: red;
  font-size: 12px;
}

.selection-container{
  width: inherit;
  display: block;
  position: relative;
  justify-content: center;
}

.leak-rate-row-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.leak-rate-container {
    margin: 1pc;
}