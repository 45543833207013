.view-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1pc;
    width: 90vh;
    height: 50vh;
    font-size: 25px;
    max-width:800px;
    max-height:150px;
}